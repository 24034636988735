<template>
  <div class="card">
    <div class="card-body">
      <div class="row">
        <div class="col-12 text-center">
          <img
            src="@/assets/img/horizon_logo_color.png"
            class="h-80px"
            alt="Portal do Cliente"
          />
        </div>
      </div>
    </div>
    <div class="card-body" v-if="isCheckingUuid">
      <h4 class="fw-light">Aguarde enquanto validamos sua chave...</h4>
    </div>
    <div class="card-body" v-else>
      <div class="row" v-if="unityInfo.status === false">
        <div class="col-12 text-center">
          <h4 class="fw-light text-block">
            {{ unityInfo.message }}
          </h4>
          <router-link to="/" class="btn btn-primary rounded-pill"
            >Página Inicial do Portal</router-link
          >
        </div>
      </div>
      <div v-if="!continueTicket && unityInfo.status" class="row">
        <div class="col-12 text-center">
          <h4 class="fw-light">Olá, {{ unityInfo.data.name }}!</h4>
          <h6 class="fw-light">
            Voce está iniciando um atendimento para o empreendimento
            {{ unityInfo.data.emprName }}, unidade
            {{ unityInfo.data.subUnityName }}, clique em prosseguir para
            continuar.
          </h6>
          <button
            class="btn btn-secondary rounded-pill mb-3"
            @click="continueTicket = true"
          >
            Prosseguir
          </button>
          <p>
            As informações acima não correspondem? Entre em contato com a SN
            Construtora.
          </p>
          <router-link to="/" class="btn btn-link"
            >Página Inicial do Portal</router-link
          >
        </div>
      </div>
      <div v-if="continueTicket && !isSuccess" class="row">
        <form @submit.prevent="openTicketWarranties">
          <div class="row">
            <div class="col-12 mb-2">
              <label for="warrantyProblemId"
                >Selecione o item com problema:</label
              >
              <select
                :disabled="isLoadingProblems"
                id="warrantyProblemId"
                class="form-select"
                :class="{ 'is-invalid': v$.selectedProblem.$errors.length }"
                placeholder="usuario@email.com"
                v-model="v$.selectedProblem.$model"
                @change="changeProblem()"
              >
                <option value="" selected disabled>Selecione um item</option>
                <option
                  v-for="problem in problems"
                  :key="problem.problemId"
                  :value="problem"
                >
                  {{ problem.itemName }} - {{ problem.problemName }}
                </option>
              </select>
              <span
                v-if="v$.selectedProblem.required.$invalid"
                class="invalid-feedback"
              >
                O campo é obrigatório
              </span>
            </div>
            <div class="col-12 mb-2">
              <label for="warrantyLocalId"
                >Selecione o cômodo onde esta o problema:</label
              >
              <select
                :disabled="isLoadingLocals"
                id="warrantyLocalId"
                class="form-select"
                :class="{ 'is-invalid': v$.warrantyLocalId.$errors.length }"
                v-model="v$.warrantyLocalId.$model"
              >
                <option value="" selected disabled>Selecione um local</option>
                <option
                  v-for="local in locals"
                  :key="local.warrantyLocalId"
                  :value="local.warrantyLocalId"
                >
                  {{ local.warrantyLocalName }}
                </option>
              </select>
              <span
                v-if="v$.selectedProblem.required.$invalid"
                class="invalid-feedback"
              >
                O campo é obrigatório
              </span>
            </div>
            <div class="col-12 mb-2">
              <label for="warrantyLocalId"
                >Deseja complementar com informações adicionais?</label
              >
              <textarea
                id="complementarInfo"
                class="form-control"
                v-model="complementarInfo"
              >
              </textarea>
            </div>
            <div class="col-12 mb-2">
              <label for="warrantyLocalId"
                >O problema está relacionado a:</label
              >
              <select
                id="warrantyRelatedBy"
                class="form-select"
                :class="{ 'is-invalid': v$.warrantyRelatedBy.$errors.length }"
                v-model="v$.warrantyRelatedBy.$model"
              >
                <option value="" selected disabled>Selecione um item</option>
                <option value="Equipamento">Equipamento</option>
                <option value="Instalação">Instalação</option>
              </select>
              <span
                v-if="v$.warrantyRelatedBy.required.$invalid"
                class="invalid-feedback"
              >
                O campo é obrigatório
              </span>
            </div>
            <div class="col-12 mb-2">
              <label for="warrantyLocalId">Anexo:</label>
              <input
                type="file"
                id="attachment"
                @change="onFilePicked"
                class="form-control"
                v-on="attachment"
              />
            </div>
            <div class="col-12">
              <div class="d-flex justify-content-between">
                <div>
                  <div v-if="!isLoadingWarrantyStatus">
                    <p v-html="warrantyStatusMessage"></p>
                  </div>
                </div>
                <button
                  class="btn btn-primary rounded-pill"
                  :disabled="
                    v$.$invalid || disableButton || warrantyStatusDisplay
                  "
                >
                  <span v-if="disableButton">
                    <i class="ph-spinner"></i> Registrando ...
                  </span>
                  <span v-else>Cadastrar</span>
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div class="row" v-if="isSuccess">
        <div class="col-12 text-center">
          <h4 class="fw-light text-block">Atendimento criado com sucesso!</h4>
          <h6 class="fw-light">
            seu protocolo de atendimento é {{ protocol }}. Para acompanhar o
            atendimento acesse o portal atraves do link abaixo. Caso ainda não
            possua acesso, contate a SN Construtora para criação da sua conta.
          </h6>
          <router-link to="/" class="btn btn-primary rounded-pill"
            >Página Inicial do Portal</router-link
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useVuelidate } from '@vuelidate/core'
import { required, minLength } from '@vuelidate/validators'

export default {
  name: 'NewTicketExternal',
  components: {},
  setup() {
    return { v$: useVuelidate() }
  },
  validations() {
    return {
      selectedProblem: {
        required,
      },
      warrantyLocalId: {
        required,
      },

      warrantyRelatedBy: { required },
    }
  },
  data() {
    return {
      isCheckingUuid: true,
      uuid: this.$route.params.uuid,
      unityInfo: null,
      continueTicket: false,

      disableButton: false,
      isLoadingProblems: true,
      isLoadingLocals: true,
      isLoadingWarrantyStatus: true,
      unity: this.$store.getters.unity,
      selectedProblem: null,
      warrantyItemId: null,
      warrantyProblemId: null,
      warrantyLocalId: null,
      warrantyGlobalSystemId: null,
      warrantyRelatedBy: null,
      complementarInfo: '',
      attachment: null,
      problems: [],
      locals: [],
      warrantyStatus: null,
      file: null,
      warrantyStatusMessage: '',
      warrantyStatusDisplay: false,
      isSuccess: false,
      protocol: null,
    }
  },
  methods: {
    async getUnityAndUserInformation() {
      try {
        this.unityInfo = await this.$store.dispatch(
          'getUnityAndUserInformation',
          this.uuid,
        )
        this.isCheckingUuid = false
        if (this.unityInfo.status) {
          this.getProblemsWarranties()
        }
      } catch (error) {
        if (error && error.message) {
          this.$root.$refs.notification.warning(error.message)
        } else {
          this.$root.$refs.notification.error(
            'Não foi possível se conectar ao servidor.',
          )
        }
      }
    },
    validateWarranty() {
      if (this.warrantyStatus.warrantyDateStatus === 'Expirada') {
        this.warrantyStatusMessage = `Garantia deste item está expirada. Válido até ${this.warrantyStatus.warrantyFinish}. Não é possível abrir um atendimento.`
        this.warrantyStatusDisplay = true
      } else if (this.warrantyStatus.warrantyMaintenanceStatus === 'Expirada') {
        const notDo = this.warrantyStatus.maintenanceList.filter(
          (item) => item.status === 'Não Realizada',
        )
        this.warrantyStatusMessage = `Item sem garantia devido não realização de uma manutenção preventiva.<br> Manutenção não realizada: ${notDo[0].name} em ${notDo[0].date}. Não é possível abrir um atendimento.`
        this.warrantyStatusDisplay = true
      }
      // this.warrantyStatusDisplay = false // Só para testes
    },
    async getProblemsWarranties() {
      try {
        this.problems = await this.$store.dispatch(
          'getProblemsWarrantiesExternal',
          this.unityInfo.data,
        )
        this.isLoadingProblems = false
      } catch (error) {
        if (error && error.message) {
          this.$root.$refs.notification.warning(error.message)
        } else {
          this.$root.$refs.notification.error(
            'Não foi possível se conectar ao servidor.',
          )
        }
      }
    },
    async getlocalsWarranties() {
      try {
        this.locals = await this.$store.dispatch(
          'getLocalsWarrantiesExternal',
          this.warrantyItemId,
        )
        this.isLoadingLocals = false
      } catch (error) {
        if (error && error.message) {
          this.$root.$refs.notification.warning(error.message)
        } else {
          this.$root.$refs.notification.error(
            'Não foi possível se conectar ao servidor.',
          )
        }
      }
    },
    async verifyWarranty() {
      try {
        this.warrantyStatus = await this.$store.dispatch(
          'verifyWarrantyExternal',
          {
            unity: this.unityInfo.data,
            warrantyItemId: this.warrantyItemId,
          },
        )
        this.validateWarranty()
        this.isLoadingWarrantyStatus = false
      } catch (error) {
        if (error && error.message) {
          this.$root.$refs.notification.warning(error.message)
        } else {
          this.$root.$refs.notification.error(
            'Não foi possível se conectar ao servidor.',
          )
        }
      }
    },
    onFilePicked(event) {
      const { files } = event.target
      const fileReader = new FileReader()
      fileReader.addEventListener('load', () => {
        this.imageUrl = fileReader.result
      })
      fileReader.readAsDataURL(files[0])
      const [fileuploaded] = files
      this.file = fileuploaded
    },
    async openTicketWarranties() {
      try {
        this.disableButton = true
        const formData = new FormData()
        formData.append('codPessEmpr', this.unityInfo.data.codPessEmpr)
        formData.append('numUnity', this.unityInfo.data.numUnity)
        formData.append('numSubUnity', this.unityInfo.data.numSubUnity)
        formData.append('item', this.warrantyItemId)
        formData.append('userName', this.unityInfo.data.name)
        formData.append('userUuid', this.unityInfo.data.uuid)
        formData.append('problem', this.warrantyProblemId)
        formData.append('local', this.warrantyLocalId)
        formData.append('related', this.warrantyRelatedBy)
        formData.append('complementarInfo', this.complementarInfo)
        formData.append('file', this.file)
        const response = await this.$store.dispatch(
          'openTicketWarrantiesExternal',
          formData,
        )
        this.isSuccess = true
        this.protocol = response.ticketProtocol
        this.$root.$refs.notification.success(response.message)
        this.disableButton = false
      } catch (error) {
        if (error && error.message) {
          this.$root.$refs.notification.warning(error.message)
        } else {
          this.$root.$refs.notification.error(
            'Não foi possível se conectar ao servidor.',
          )
        }
      }
    },
    changeProblem() {
      this.warrantyItemId = this.selectedProblem.warrantyItemId
      this.warrantyProblemId = this.selectedProblem.problemId
      this.warrantyLocalId = null
      this.getlocalsWarranties()
      this.verifyWarranty()
    },
  },
  mounted() {
    this.getUnityAndUserInformation()
  },
}
</script>
