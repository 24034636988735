<template>
  <div class="text-center mb-2">
    <img
      src="@/assets/img/horizon_logo_color.png"
      class="h-80px"
      alt="Portal do Cliente"
    />
  </div>
</template>

<script>
export default {
  name: 'Logo',

  components: {},

  computed: {},

  methods: {},
}
</script>
